import React from 'react';
import {Progress,Row,Col} from 'reactstrap';
// import ScheduledReturnForm from './ScheduledReturnForm';
import {flowRight as compose} from 'lodash';
import { graphql } from 'react-apollo';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import ConfirmReturnForm from './ConfirmReturnForm';

const EnumQuery = gql`
query EnumQuery{
     carReturnStates:__type(name: "CarReturnStage") {
        states: enumValues {
            name
            description
        }
     }
}
`;

const DriverQuery = gql`
query DriverQuery($id:ID!){
    driver(id:$id){
        id
        firstName
        lastName
        currentAgreement{
            id
            stage
            startDate
            car{
              pk
              dmvPlate
              model
              year
            }
            carreturn{
              id
              stage
              scheduledDate
              nextPickupDate
            }
        }
    }
}
`

class FormSwitch extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      dafault:true
    }
  }
  updateInput = (e) => {
    let name = e.target.name
    this.setState({[name]:e.target.value})
  }
  convertEnumToInt(text){
    if(text && text.includes("A_")){
        text = text.replace("A_","")
    }else if(text && text.includes("_")){
      text = text.replace("_","")
    }
    if(!isNaN(parseInt(text))){
        text = parseInt(text)
    }
    return text
  }
  render() {
    return (
        this.props.driver && this.props.driver.currentAgreement
        ? (
          this.props.driver.currentAgreement.carreturn && this.convertEnumToInt(this.props.driver.currentAgreement.carreturn.stage) >= 1 ? (
            this.convertEnumToInt(this.props.driver.currentAgreement.carreturn.stage)===1 ? <ConfirmReturnForm driver={this.props.driver} returnStages={this.props.carReturnStates}/>:
            <Row><Col xs={12} className="text-muted">Your schedule has already been confirmed. Please call Buggy support for more info!</Col></Row>
          ):
            // <ScheduledReturnForm driver={this.props.driver} type={this.props.match&&this.props.match.params&&this.props.match.params.type&&this.props.match.params.type==="switch"?"switch":"return"}/>:
            <Row><Col xs={12} className="text-danger">No scheduled return found, please schedule a return first using Buggy Driver Mobile App or call Buggy support!</Col></Row>
        ):this.props.loading ? <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>
        :<Row><Col xs={12} className="text-danger">No Active Rental Found!</Col></Row>
    );
  }
}
export default compose (
    withApollo,
    graphql(EnumQuery, {props({data: {carReturnStates}}) { return {carReturnStates}}}),
    graphql(DriverQuery, {
        options: ({match}) => ({variables: {id:match.params.id}}),
        props({data: {driver,loading}}) { 
            return {driver,loading}
        }
      }),
)(FormSwitch);