import React from 'react';
import { FormGroup, Row, Col, Label, Input, Alert, Button} from 'reactstrap';
import {flowRight as compose} from 'lodash';
import { graphql } from 'react-apollo';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
const AllScheduleClosingDays = gql`{
  allScheduleClosingDays{
    edges{
      node{
        scheduleDate
        reason
      }
    }
  }
}`;
// const ConfirmReturn = gql`
// mutation confirmReturn($input: ConfirmReturnMutationInput!){
//   confirmReturn(input:$input){
//     ok
//     errors{
//         messages
//     }
// }
// } `;
// const CancelReturn = gql `
//   mutation CancelReturn ($input:CancelReturnInput!){
//       cancelReturn(input:$input){
//           ok
//           errors{
//               messages
//           }
//       }
// }`;
const ChangeReturn = gql `
  mutation ChangeReturn ($input:ChangeReturnInput!){
      changeReturn(input:$input){
          ok
          errors{
              field
              messages
          }
      }
  }`;
class ConfirmReturnForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        rescheduleDate:moment().add(1, 'days').format("YYYY-MM-DD"),
        confirmOrReschedule:null,
        showDateNotAvailableError:false,
        success:false
    }
  }
  updateInput = (e) => {
    let name = e.target.name
    let value = e.target.value
    if(e.target.type==="date"){
      if(moment(value).isValid()){
        let isSaturday = moment(value).weekday() === 6;
        let dateNotAvailableError = isSaturday;
        if(this.props.allScheduleClosingDays &&  this.props.allScheduleClosingDays.edges &&  this.props.allScheduleClosingDays.edges.length>0){
          const closingDays = this.props.allScheduleClosingDays.edges.map((item,i)=>{return item.node.scheduleDate})
          if(closingDays.includes(moment(value).format("YYYY-MM-DD"))){
            dateNotAvailableError = true
          }
        }
        if(dateNotAvailableError){
          this.setState({showDateNotAvailableError:true})
        }else{
          this.setState({showDateNotAvailableError:false})
        }
      }
    }
    this.setState({[name]:value})
  }
  submit = () => {
    if(this.props.driver&&this.props.driver.currentAgreement&&this.props.driver.currentAgreement.id){
      try {
        this.setState({loading: true});
        let stage = null
        
        let input;
        if(this.state.confirmOrReschedule === "cancel"){
          if (this.props.returnStages && this.props.returnStages.states && this.props.returnStages.states.length>0 && this.props.returnStages.states.filter(stage => stage.description === "Cancelled").length > 0){
            stage = this.props.returnStages.states.filter(stage  => stage.description === "Cancelled")[0].name.replace("A_", "")
          }else{
            this.setState({loading:false, success:false, error:"Unable to find cancel stage in return. Please contact admin!"});
            return
          }
          input = {
              carReturnId: this.props.driver.currentAgreement.carreturn.id,
              returnStage: parseInt(stage),
              actualReturnReason: "Driver cancelled the return from SMS Confirmation URL",
          };
        }else if(this.state.confirmOrReschedule){
          if (this.props.returnStages && this.props.returnStages.states && this.props.returnStages.states.length>0 && this.props.returnStages.states.filter(stage => stage.description === "Confirmed").length > 0){
            stage = this.props.returnStages.states.filter(stage  => stage.description === "Confirmed")[0].name.replace("A_", "")
          }else{
            this.setState({loading:false, success:false, error:"Unable to find confirmed stage in return. Please contact admin!"});
            return
          }
          input = {
              carReturnId: this.props.driver.currentAgreement.carreturn.id,
          };
          if(this.state.confirmOrReschedule === "confirmReturn"){
            input["returnStage"] = parseInt(stage);
          } else if (this.state.confirmOrReschedule === "reschedule" && this.state.rescheduleDate){
            input["returnDate"] = this.state.rescheduleDate
          } else{
            this.setState({loading:false, success:false, error:"Something went wrong, Please contact Admin!"});
            return
          }
        }
        
        
        this.props.client.mutate({
            mutation: ChangeReturn,
            variables: {input},
        }).then((result)=>{
            if(result.data && result.data.changeReturn && result.data.changeReturn.ok){
                this.setState({loading:false,error:null,success:true});
                setTimeout(() => {window.location.replace('https://www.joinbuggy.com')}, 800)
            }else{
                let errorMessage = "An error has occured. Please contact system admin"
                if(result.data && result.data.changeReturn && result.data.changeReturn.errors && result.data.changeReturn.errors[0] && result.data.changeReturn.errors[0].messages[0]){
                  errorMessage = result.data.changeReturn.errors[0].messages[0]
                }
                this.setState({loading:false,success:false,error:errorMessage});
            }
        }).catch((error)=>{
            let errorMessage = "An error has occured";
            this.setState({error:errorMessage,loading:false,success:false,})
        })
      }catch(err) {
          let errorMessage = "An error has occured";
          this.setState({error:errorMessage,loading:false,success:false})
      }
    }
  };
  render() {
    let driver = this.props.driver;
    return (
      this.state.success?
      <Row className="ScheduleReturnForm">
          <Col xs={12}>
            {this.state.confirmOrReschedule==="cancel"?
            <p>Congratulations, your return has been canceled successfully!</p>
            :
            <p>Congratulations, your return information has been updated successfully!</p>
            }
              
          </Col>
      </Row>:
      driver?
      <>
        <Row className="ScheduleReturnForm">
          <Col xs={12}>
              <p>Hi {driver.firstName}, would you like to <strong>confirm your return</strong>, <strong>cancel</strong> or <strong>change your return date</strong>?</p>
          </Col>
        </Row>
        <Row className="py-3">
          <Col xs={3}>
            <p className="columnTitle">Driver Name</p>
            <p className="columnContent">{driver.firstName+" "+driver.lastName}</p>
          </Col>
          <Col xs={3}>
            <p className="columnTitle">Agreement Start Date</p>
            <p className="columnContent">{moment(driver.currentAgreement.startDate).format("YYYY-MM-DD")}</p>
          </Col>
          <Col xs={3}>
            <p className="columnTitle">Car Model</p>
            <p className="columnContent">{driver.currentAgreement.car.model + " " + driver.currentAgreement.car.year}</p>
          </Col>
          <Col xs={3}>
            <p className="columnTitle">DMV Plate</p>
            <p className="columnContent">{driver.currentAgreement.car.dmvPlate}</p>
          </Col>
          <Col xs={3}>
            <p className="columnTitle">Return Scheduled Date</p>
            <p className="columnContent">{moment(driver.currentAgreement.carreturn.scheduledDate).format("YYYY-MM-DD")}</p>
          </Col>
          <Col xs={3}>
            <p className="columnTitle">Future Pickup Date</p>
            <p className="columnContent">{driver.currentAgreement.carreturn.nextPickupDate ? moment(driver.currentAgreement.carreturn.nextPickupDate).format("YYYY-MM-DD") : "--"}</p>
          </Col>
        </Row>
        <Row className="text-right">
          <Col xs={12}>
            <FormGroup>
              <Button onClick={()=>this.setState({confirmOrReschedule:"reschedule"})}>
                {this.state.confirmOrReschedule==="reschedule"&&<i className="fa fa-check-circle" aria-hidden="true"></i>}
                &nbsp;Change Date</Button>&nbsp;&nbsp;&nbsp;
              <Button onClick={()=>this.setState({confirmOrReschedule:"confirmReturn"})}>
              {this.state.confirmOrReschedule==="confirmReturn"&&<i className="fa fa-check-circle" aria-hidden="true"></i>}
              &nbsp;Confirm Return</Button>&nbsp;&nbsp;&nbsp;
              <Button onClick={()=>this.setState({confirmOrReschedule:"cancel"})}>
                {this.state.confirmOrReschedule==="cancel"&&<i className="fa fa-check-circle" aria-hidden="true"></i>}
                &nbsp;Cancel Return</Button>
            </FormGroup>
          </Col>
          {this.state.confirmOrReschedule === "reschedule" &&
            <>
              <Col xs={12} className="text-left" >
                  <FormGroup>
                      <Label for="rescheduleDate">What date would you like to reschedule to?</Label>
                      <Alert color="info">
                        Please note that we're closed on Saturdays and do not accept returns on that day
                      </Alert>
                      {this.state.showDateNotAvailableError&&
                        <Alert color="danger">
                          Sorry, the company will be closed on the selected day. Please choose a different day.
                          <br/>Please note the minimum rental is one week.
                        </Alert>
                      }
                      <Input type="date" name="rescheduleDate" value={this.state.rescheduleDate} 
                        min={moment().format(moment.HTML5_FMT.DATE)} id="rescheduleDate" 
                        onChange={this.updateInput}
                      />
                  </FormGroup>
              </Col>
            </>
          }
          <Col xs={12} className="text-danger">
            {this.state.error}
          </Col>
          <Col xs={12}>
            <Button 
              disabled={
                !this.state.confirmOrReschedule || this.state.loading ||
                (this.state.confirmOrReschedule === "reschedule" && (!this.state.rescheduleDate || this.state.showDateNotAvailableError))
              }
              onClick={()=>this.submit()}
              color="primary">{this.state.loading?"Loading...":"Submit"}
            </Button>
          </Col>
        </Row>
        </>
        :
        <span>&nbsp;</span>
    );
  }
}
export default compose (
  withApollo,
  graphql(AllScheduleClosingDays, {props({ data:{allScheduleClosingDays} }){return {allScheduleClosingDays};}}),
)(ConfirmReturnForm);